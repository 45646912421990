import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [2];

export const dictionary = {
		"/(web)": [~9,[2]],
		"/admin": [34],
		"/(web)/auth/auth-code-error": [10,[2]],
		"/(web)/blog/add": [~13,[2],[,4]],
		"/(web)/blog/[slug]": [11,[2]],
		"/(web)/blog/[slug]/edit": [~12,[2],[,3]],
		"/(web)/delete-account": [14,[2]],
		"/(web)/faqs": [~16,[2]],
		"/(web)/faq": [15,[2]],
		"/(web)/help": [17,[2]],
		"/(web)/privacy": [18,[2]],
		"/(web)/profile": [~19,[2]],
		"/(web)/progress": [~20,[2]],
		"/(web)/reset-password": [21,[2]],
		"/(web)/reviews": [22,[2]],
		"/(web)/reviews/add": [~25,[2]],
		"/(web)/reviews/[review_id]": [~23,[2],[,5]],
		"/(web)/reviews/[review_id]/edit": [~24,[2],[,5,6]],
		"/(web)/set-username": [~26,[2]],
		"/(web)/signin": [27,[2]],
		"/(web)/sodas": [28,[2]],
		"/(web)/sodas/add": [~30,[2]],
		"/(web)/sodas/[slug]": [29,[2],[,7]],
		"/splash": [35],
		"/(web)/terms": [31,[2]],
		"/(web)/update-password": [32,[2]],
		"/(web)/users/[username]": [~33,[2],[,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';